// 
// Widgets.scss
// 

.mini-stats-wid{
    .mini-stat-icon{
        overflow: hidden;
        position: relative;
        &:before, &:after{
            content: "";
            position: absolute;
            width: 8px;
            height: 54px;
            background-color: rgba($white,.1);
            left: 16px;
            transform: rotate(32deg);
            top: -5px;
            transition: all 0.4s;
        }

        &::after{
            left: -12px;
            width: 12px;
            transition: all 0.2s;
        }
    }

    &:hover{
        .mini-stat-icon{
            &::after{
                left: 60px;
            }
        }
    }
}

.text-right {
    text-align: right;
}

.py-welcome {
    padding: 3rem 0;
 
    @media (max-width: 600px) {
        padding: 1rem 0;
    }
}

.w-welcome {
    width: 250px;

    @media screen and (max-width: 600px) {
        width: 80px
    }
}

.my-detail {
    margin: 0 0;

    @media screen and (max-width: 600px) {
        margin: 1rem 0;
    }
}
  


